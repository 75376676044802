// COLUMN CONTENT - BOX BORDER
.o-cc-bb {
	&__icon {
		margin-bottom: 1rem;
	}

	&__title {
		color: $color-green-5;
		font-family: $font-neutraface;
		font-size: 2.8rem;
		font-weight: 600;
		line-height: 1.2;
		margin-bottom: 1rem;
		margin-top: 0;
	}

	&__text {
		p {
			color: $color-jet;
			opacity: 0.7;
		}
	}
}
