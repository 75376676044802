.post-list-d {
	$this: &;

	&__row {
		display: flex;
		flex-flow: row wrap;
	}

	&__aside {
		// remove sidebar for now, client recommended it
		display: none;
		flex: 0 1 auto;
		margin-bottom: 5rem;
		width: 25%;

		@include breakpoint(1080px down) {
			width: 30%;
		}

		@include breakpoint(809px down) {
			width: 100%;
		}
	}

	&__search {
		margin: 0 auto 5rem;
		position: relative;
		z-index: 12;
	}

	&__form-field {
		position: relative;

		svg {
			left: 0;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}

		input {
			@extend %o-text-regular-16;
			border: 0;
			border-bottom: 0.1rem solid $color-gray-1;
			border-radius: 0 !important;
			box-shadow: none;
			color: $color-gray-2;
			height: auto;
			padding: 0.8rem 0.8rem 0.8rem 4rem;
			width: 100%;

			@each $prefix in $placeholder_prefixes {
				&#{$prefix} {
					color: $color-gray-2;
					font: inherit;
					opacity: 1;
				}
			}

			&:focus {
				border: 0;
				border-bottom: 0.1rem solid $color-gray-1;
				box-shadow: none;

				@each $prefix in $placeholder_prefixes {
					&#{$prefix} {
						color: $color-gray-2;
						font: inherit;
						opacity: 0;
					}
				}
			}
		}
	}

	&__result {
		background-color: $color-white-1;
		border-bottom: 0.1rem solid $color-gray-2;
		border-left: 0.1rem solid $color-gray-2;
		border-right: 0.1rem solid $color-gray-2;
		display: none;
		left: 0;
		list-style: none;
		padding: 0;
		position: absolute;
		top: 100%;
		width: 100%;
		z-index: 2;

		li {
			border-bottom: 0.1rem solid $color-gray-2;

			p,
			a {
				@extend %o-text-regular-16;
				color: $color-gray-2;
				display: block;
				line-height: 1.2;
				margin-bottom: 0;
				padding: 0.5rem 1rem;
			}

			a {
				&:hover {
					text-decoration: none;
				}
			}

			&:last-child {
				border-bottom: 0;
			}
		}
	}

	&__tag {
		&.active {
			#{$this}__tag-link {
				&:before {
					display: none;
				}
			}
		}
	}

	&__tag-box {
		height: 43.8rem;
		overflow: hidden;
		transition: all 0.4s ease;

		@include breakpoint(809px down) {
			height: 14.3rem;
		}

		ul {
			margin-left: -0.5rem;
			width: calc(100% + 1rem);

			li {
				display: inline-block;
				margin-bottom: 1rem;
				padding: 0 0.5rem;

				a {
					@extend %o-text-regular-16;
					border: 0.1rem solid $color-gray-3;
					border-radius: 10rem;
					color: $color-gray-3;
					cursor: pointer;
					display: inline-block;
					line-height: 1;
					padding: 1rem 2.5rem;
					text-decoration: none;

					&:hover {
						text-decoration: none;
					}

					&.active {
						border-color: $color-green-1;
						color: $color-green-1;
					}
				}
			}
		}
	}

	&__tag-link {
		padding-top: 2.5rem;
		position: relative;
		text-align: center;

		&:before {
			background: rgb(255, 255, 255);
			background: -moz-linear-gradient(
				180deg,
				rgba(255, 255, 255, 0) 0%,
				rgba(255, 255, 255, 1) 90%
			);
			background: -webkit-linear-gradient(
				180deg,
				rgba(255, 255, 255, 0) 0%,
				rgba(255, 255, 255, 1) 90%
			);
			background: linear-gradient(
				180deg,
				rgba(255, 255, 255, 0) 0%,
				rgba(255, 255, 255, 1) 90%
			);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
			bottom: 100%;
			content: "";
			left: 0;
			height: 8rem;
			pointer-events: none;
			position: absolute;
			width: 100%;
		}
	}

	&__body {
		flex: 0 1 auto;
		padding-left: 2.5rem;
		// remove sidebar for now, client recommended it
		// width: 75%;

		// @include breakpoint(1080px down) {
		// 	width: 70%;
		// }

		// @include breakpoint(809px down) {
		// 	padding-left: 0;
		// 	width: 100%;
		// }
	}

	&__body-items {
		display: flex;
		flex-flow: row wrap;
		margin-left: -1.25rem;
		width: calc(100% + 2.5rem);

		@include breakpoint(809px down) {
			margin-left: 0;
			width: 100%;
		}
	}

	&__body-item {
		flex: 0 1 auto;
		margin-bottom: 3.5rem;
		padding: 0 1.25rem;
		width: 33.33%;

		@include breakpoint(1080px down) {
			width: 50%;
		}

		@include breakpoint(809px down) {
			padding: 0;
			width: 100%;
		}
	}

	&__body-item-box {
		box-shadow: 0.2rem 0.2rem 1.5rem #e4e8ef;
		border-radius: 0.8rem;
		height: 100%;
		overflow: hidden;
		position: relative;
	}

	&__body-item-img {
		@extend %background-image;
		height: 19rem;
		position: relative;
		width: 100%;

		@include breakpoint(1079px down) {
			height: 15rem;
		}

		@include breakpoint(809px down) {
			height: 19rem;
		}
	}

	&__body-item-content {
		background-color: $color-white-1;
		height: calc(100% - 19rem);
		padding: 3.5rem 2rem;

		@include breakpoint(1079px down) {
			height: calc(100% - 15rem);
		}

		@include breakpoint(809px down) {
			height: calc(100% - 19rem);
		}
	}

	&__body-item-cat {
		@extend %o-text-regular-16;
		color: $color-gray-5;
		line-height: 1.2;
		margin-bottom: 1rem;
	}

	&__body-item-title {
		@extend %o-title-bold-18;
		color: $color-gray-5;
		line-height: 1.2;
		margin-bottom: 0;
		margin-top: 0;
	}

	&__body-pagination {
		flex: 0 1 auto;
		margin-top: 3.5rem;
		padding: 0 1.25rem;
		text-align: center;
		width: 100%;

		@include breakpoint(809px down) {
			margin-top: 1.5rem;
		}

		ul {
			list-style: none;

			li {
				display: inline-block;
				margin: 0 0.75rem;

				.page-numbers {
					color: $color-black-1;
					font-size: 1.6rem;
					line-height: 1;

					&:hover {
						text-decoration: none;
					}

					&.current {
						color: $color-green-1;
						font-weight: 700;
					}
				}
			}
		}
	}
}
