.stat-d {
	$this: &;

	&__grid {
		> * {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__title {
		@extend %o-title-medium-36;
		color: $color-green-5;
		line-height: 1.2;
		margin-bottom: 3rem;
		margin-top: 0;
	}

	&__row {
		display: flex;
		flex-flow: row wrap;
		margin-left: -1.5rem;
		width: calc(100% + 3rem);

		@include breakpoint(809px down) {
			margin-left: 0;
			width: 100%;
		}
	}

	&__col {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		margin-bottom: 1.5rem;
		padding: 0 1.5rem;
		width: 25%;

		@include breakpoint(1079px down) {
			width: 50%;
		}

		@include breakpoint(809px down) {
			padding: 0;
			width: 100%;
		}
	}

	&__col-no {
		@extend %o-title-bold-36;
		flex: 0 0 auto;
		color: $color-green-4;
		line-height: 1.2;
	}

	&__col-content {
		flex: 1 1 0;
		padding-left: 1.5rem;

		p {
			color: $color-gray-10;
			font-size: 1.8rem;
			line-height: 1.3;
		}
	}
}
