.elem-photo-content-d {
	$this: &;

	&.content-left {
		@include breakpoint(810px up) {
			#{$this}__content {
				order: 1;
			}

			#{$this}__media {
				order: 2;
			}

			&.layout-default {
				#{$this}__media {
					padding-left: 10%;
				}
			}

			&.layout-narrow-gap {
				#{$this}__media {
					padding-left: 5%;
				}
			}
		}
	}

	&.content-right {
		@include breakpoint(810px up) {
			#{$this}__content {
				order: 2;
			}

			#{$this}__media {
				order: 1;
			}

			&.layout-default {
				#{$this}__media {
					padding-right: 10%;
				}
			}

			&.layout-narrow-gap {
				#{$this}__media {
					padding-right: 5%;
				}
			}
		}
	}

	&__media {
		@include breakpoint(810px up) {
			width: 55%;
		}

		@include breakpoint(809px down) {
			margin-bottom: 2rem;
		}
	}

	&__img {
		img {
			width: 100%;
		}
	}

	&__content {
		@include breakpoint(810px up) {
			width: 45%;
		}
	}
}
