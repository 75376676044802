.o-cc-ca-c2 {
	height: 100%;

	&__wrap {
		position: relative;
	}

	&__icon {
		margin-bottom: 1.5rem;
	}

	&__title {
		@extend %o-title-semibold-24;
		color: $color-onyx;
		line-height: 1;
		margin-bottom: 0;
	}
}
