.column-content-bb {
	$this: &;

	&.col-3 {
		$gap: 4rem;

		#{$this}__row {
			margin-left: -$gap;
			margin-right: -$gap;

			@include breakpoint(809px down) {
				margin-left: 0;
				margin-right: 0;
			}
		}

		#{$this}__col {
			width: 33.3333%;
			padding-left: $gap;
			padding-right: $gap;

			@include breakpoint(1079px down) {
				width: 50%;
				margin-bottom: 4rem;
			}

			@include breakpoint(809px down) {
				width: 100%;
				padding-left: 0;
				padding-right: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&.col-4 {
		$gap: 2rem;

		#{$this}__row {
			margin-left: -$gap;
			margin-right: -$gap;

			@include breakpoint(809px down) {
				margin-left: 0;
				margin-right: 0;
			}
		}

		#{$this}__col {
			width: 25%;
			padding-left: $gap;
			padding-right: $gap;

			@include breakpoint(1079px down) {
				width: 50%;
				margin-bottom: 4rem;
			}

			@include breakpoint(809px down) {
				width: 100%;
				padding-left: 0;
				padding-right: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		#{$this}__col-title {
			font-size: 2.4rem;
		}
	}

	&.grid-border-radius-36 {
		#{$this}__grid {
			border-radius: 3.6rem;
		}
	}

	&.grid-box-shadow-0-0-30 {
		#{$this}__grid {
			box-shadow: 0 0 3rem rgba(41, 72, 100, 0.07);
		}
	}

	&.grid-padding-90-100 {
		#{$this}__grid {
			padding: 9rem 10rem;

			@include breakpoint(1080px down) {
				padding: 5rem;
			}
		}
	}

	&.grid-padding-90-75 {
		#{$this}__grid {
			padding: 9rem 7.5rem;

			@include breakpoint(1080px down) {
				padding: 5rem;
			}
		}
	}

	&__title {
		@extend %o-title-medium-36;
		color: $color-green-5;
		line-height: 1.2;
		margin-bottom: 5rem;
		margin-top: 0;
		text-align: center;

		@include breakpoint(809px down) {
			margin-bottom: 3rem;
		}
	}

	&__col {
		position: relative;
		text-align: center;

		@include breakpoint(1079px down) {
			&:nth-child(2n) {
				&::after {
					display: none;
				}
			}
		}

		&:last-child {
			&:after {
				display: none;
			}
		}

		&:after {
			background-color: $color-gray-10;
			content: "";
			width: 0.1rem;
			height: 10rem;
			margin-top: -5rem;
			position: absolute;
			top: 50%;
			right: 0;

			@include breakpoint(809px down) {
				display: none;
			}
		}
	}
}
