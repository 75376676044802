.o-sli-te-d {
	border: 1px solid #c1c1c1;
	border-radius: 1.5rem;
	height: 100%;

	&__content {
		padding: 3.1rem 4.3rem;

		@include breakpoint(1079px down) {
			padding: 3.1rem;
		}
	}

	&__icon {
		margin-bottom: 2.5rem;
	}

	&__text {
		h1,
		h2,
		h3 {
			@extend %o-title-medium-24;
			margin-bottom: 2.5rem;
		}

		ul {
			li {
				color: $color-charcoal;
				line-height: 1.6;
				margin-left: 1.5rem;
				margin-bottom: 0;
				list-style-position: outside;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}
