.column-content-ca {
	$this: &;

	&.layout-card-2 {
		#{$this}__col {
			@include breakpoint(1080px up) {
				flex: 1 1 0px !important;
				width: auto !important;
			}
		}
	}

	&.layout-card-3 {
		&.col-gap-70 {
			$gap: 3.5rem;
	
			#{$this}__row {
				@include breakpoint(768px up) {
					margin-left: -$gap;
					margin-right: -$gap;
				}
			}
	
			#{$this}__col {
				@include breakpoint(768px up) {
					padding-left: $gap;
					padding-right: $gap;
					margin-bottom: #{$gap * 2};
				}
			}
		}

		#{$this}__col {
			@include breakpoint(768px up) {
				max-width: 100%;
				flex: 0 0 auto !important;
				width: auto !important;
			}
		}
	}

	&.layout-logo-1 {
		&.col-gap-50 {
			$gap: 2.5rem;
	
			#{$this}__row {
				margin-left: -$gap;
				margin-right: -$gap;
			}
	
			#{$this}__col {
				padding-left: $gap;
				padding-right: $gap;
				margin-bottom: #{$gap * 2};

				&:last-child {
					margin-bottom: #{$gap * 2};
				}
			}
		}

		#{$this}__col {
			&.col-20 {
				width: 50%;

				@include breakpoint(768px up) {
					width: 33.3333%;
				}

				@include breakpoint(1080px up) {
					width: 20%;
				}
			}

			&.col-25 {
				width: 50%;

				@include breakpoint(768px up) {
					width: 33.3333%;
				}

				@include breakpoint(1080px up) {
					width: 25%;
				}
			}

			&.col-33 {
				width: 50%;

				@include breakpoint(768px up) {
					width: 33.3333%;
				}
			}
		}
	}

	&.col-gap-30 {
		$gap: 1.5rem;

		#{$this}__row {
			margin-left: -$gap;
			margin-right: -$gap;

			@include breakpoint(1079px down) {
				margin-left: 0;
				margin-right: 0;
			}
		}

		#{$this}__col {
			padding-left: $gap;
			padding-right: $gap;
			margin-bottom: #{$gap * 2};

			@include breakpoint(1079px down) {
				padding-left: 0;
				padding-right: 0;
				margin-bottom: 3.5rem;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	&.col-gap-40 {
		$gap: 2rem;

		#{$this}__row {
			margin-left: -$gap;
			margin-right: -$gap;

			@include breakpoint(1079px down) {
				margin-left: 0;
				margin-right: 0;
			}
		}

		#{$this}__col {
			padding-left: $gap;
			padding-right: $gap;
			margin-bottom: #{$gap * 2};

			@include breakpoint(1079px down) {
				padding-left: 0;
				padding-right: 0;
				margin-bottom: 3.5rem;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	&.col-gap-50 {
		$gap: 2.5rem;

		#{$this}__row {
			margin-left: -$gap;
			margin-right: -$gap;

			@include breakpoint(1079px down) {
				margin-left: 0;
				margin-right: 0;
			}
		}

		#{$this}__col {
			padding-left: $gap;
			padding-right: $gap;
			margin-bottom: #{$gap * 2};

			@include breakpoint(1079px down) {
				padding-left: 0;
				padding-right: 0;
				margin-bottom: 2.5rem;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	&.col-gap-70 {
		$gap: 3.5rem;

		#{$this}__row {
			margin-left: -$gap;
			margin-right: -$gap;

			@include breakpoint(1079px down) {
				margin-left: 0;
				margin-right: 0;
			}
		}

		#{$this}__col {
			padding-left: $gap;
			padding-right: $gap;
			margin-bottom: #{$gap * 2};

			@include breakpoint(1079px down) {
				padding-left: 0;
				padding-right: 0;
				margin-bottom: 3.5rem;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	&__col {
		&.col-25 {
			@include breakpoint(1080px up) {
				width: 25%;
			}
		}

		&.col-33 {
			@include breakpoint(1080px up) {
				width: 33.3333%;
			}
		}

		&.col-50 {
			@include breakpoint(1080px up) {
				width: 50%;
			}
		}
	}
}
