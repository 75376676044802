.has-text-align-center {
	margin-left: 10vw;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

#info_outer {
	--color-text: #f6f9fc;
	--color-dark-text: #001300;
	--color-surface: #001300;
	--color-light-surface: #fff;
	--color-btn-off: #44912d;
	--color-btn-hover: #66da43;

	@include breakpoint(809 down) {
		display: none;
	}
}

.tooltip {
	position: relative;
	max-width: 90%;

	@include breakpoint(1080 down) {
		max-width: 60%;
	}

	@include breakpoint(809 down) {
		display: none;
	}

	button {
		cursor: pointer;
	}
}

.tooltip .tooltiptext {
	background-color: var(--color-dark-text);
	color: var(--color-light-surface);
	position: absolute;
	z-index: 1;
	visibility: hidden;
	display: none;
}

.tooltip:hover .tooltiptext {
	visibility: visible;
	display: block;
}

#info {
	margin-top: 1rem;
	border: 2px solid var(--color-light-surface);
	border-radius: 10px;
	padding: 10px;
}

#info table,
#info th,
#info td {
	border: 1px solid var(--color-light-surface);
	border-collapse: collapse;
	vertical-align: top;
	color: var(--color-light-surface);
	font-size: 200%;
}

#info caption {
	font-size: 200%;
}

.submit-btn {
	margin-left: 1rem;
	padding-bottom: 1rem;
	background-color: var(--color-btn-off);
	border-radius: 40px;
	color: var(--color-text);
	font-weight: 600;
}

.submit-btn:hover {
	opacity: 1;
	background-color: var(--color-btn-hover);
}

.info_circle_img {
	height: 2rem;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 5px;
	display: block;
}

#info_button {
	width: 100px;
	font-size: 300%;
	z-index: 2;
}
