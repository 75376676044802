%blog-l-title {
	color: $color-white-1;
	font-family: $font-neutraface;
	font-size: 6rem;
	line-height: 1.2;
}

.blog-l {
	$this: &;
	$trans: 0.4s ease 0s;

	&__banner {
		background-color: $color-black-1;
		height: 27vh;

		@include breakpoint(1023px down) {
			height: 20vh;
		}

		@include breakpoint(767px down) {
			height: auto;
			padding: 10rem 5rem;
		}
	}

	&__banner-title {
		@extend %blog-l-title;
		margin-bottom: 0;

		@include breakpoint(767px down) {
			font-size: 3rem;
		}
	}

	&__featured {
		background-color: $color-black-1;
		padding: 6rem 0;
	}

	&__featured-title {
		@extend %blog-l-title;
		margin-bottom: 3rem;
	}

	&__filter {
		padding: 10rem 0 5rem;

		@include breakpoint(767px down) {
			padding: 5rem 0;
		}
	}

	&__cat {
		@include breakpoint(768px up) {
			@include auto();
		}
	}

	&__cat-list {
		@include breakpoint(1023px down) {
			display: none;
		}

		li {
			list-style-type: none;
			margin-right: 3rem;

			a {
				color: #666;
				font-family: $font-neutraface;
				font-size: 1.3rem;
				line-height: 2.1rem;
				text-transform: uppercase;
				transition: none;

				&:hover {
					color: $color-black-1;
				}

				&.selected {
					border-bottom: 0.3rem solid $color-black-1;
					color: $color-black-1;
				}
			}

			span {
				&:hover {
					border-bottom: 0.3rem solid $color-black-1;
				}
			}
		}
	}

	&__cat-select {
		background-image: none;
		border: 1px solid #bbb;
		border-radius: 1rem;
		font-family: $font-neutraface;
		font-size: 1.4rem;
		line-height: 4rem;
		width: 65%;
		height: 4rem;
		padding: 0 1rem;
		position: relative;
		appearance: auto;

		@include breakpoint(1024px up) {
			display: none;
		}

		@include breakpoint(767px down) {
			width: 100%;
		}

		option {
			color: $color-black-1;
			font-family: $font-neutraface;
			font-size: 1.4rem;
			line-height: 1.3;
		}

		&:focus {
			background-color: transparent;
			border: 1px solid $color-black-1;
			box-shadow: none;
			transition: none;
		}
	}

	&__search {
		position: relative;

		@include breakpoint(768px up) {
			@include shrink();
			width: 21.5rem;
		}
	}

	&__search-form {
		background-color: $color-white-1;
		position: relative;

		@include breakpoint(1024px up) {
			top: -1rem;
		}

		input {
			background-color: transparent;
			border-radius: 1rem;
			box-shadow: none;
			color: $color-black-1;
			font-family: $font-neutraface;
			font-size: 1.3rem;
			line-height: 4rem;
			width: 100%;
			height: 4rem;
			padding: 1rem;
			margin-bottom: 0;
			transition: none;

			&:focus {
				background-color: transparent;
				border: 1px solid $color-black-1;
				box-shadow: none;
				transition: none;
			}

			@each $prefix in $placeholder_prefixes {
				&#{$prefix} {
					color: $color-black-1;
					opacity: 0.5;
				}
			}
		}
	}

	&__search-btn {
		background-color: transparent;
		border-radius: 0;
		box-shadow: none;
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);

		&::after {
			content: "";
			background: url("../images/ico_search.svg");
			background-size: contain;
			background-repeat: no-repeat;
			width: 1.5rem;
			height: 1.5rem;
			position: absolute;
			top: 55%;
			right: 1.2rem;
			transform: translateY(-50%);
		}
	}

	&__search-result {
		background: $color-white-1;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
		display: none;
		width: 100%;
		padding: 1rem 2rem;
		position: absolute;
		top: 3rem;
		left: 0;
		z-index: 3;

		li {
			margin-bottom: 1rem;

			a {
				color: $color-black-1;
				font-family: $font-neutraface;
				font-size: 1.3rem;
				line-height: 1.7rem;
				opacity: 0.5;
				transition: color $trans, opacity $trans;

				&:hover {
					color: $color-green-1;
					text-decoration: none;
					opacity: 1;
				}
			}
		}
	}

	&__list-row {
		width: calc(100% + 4rem);
		margin-left: -2rem;
	}

	&__list-col {
		padding: 0 2rem;
		margin-bottom: 3rem;

		@include breakpoint(767px down) {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__pagination {
		padding: 0 2rem;
		margin-top: 5rem;

		@include breakpoint(767px down) {
			margin-top: 2rem;
		}
	}

	// Category page

	&__banner-category-title-wrapper {
		min-height: 50vh;

		@include breakpoint(1023px down) {
			min-height: 20vh;
		}

		@include breakpoint(767px down) {
			padding: 10rem;
		}
	}

	&__banner-category-title {
		text-transform: none;

		@include breakpoint(767px down) {
			font-size: 3rem;
		}
	}
}

.page-template-page-blog-lists {
	#main-footer {
		display: none;
	}
}

.category {
	#main-footer {
		display: none;
	}
}

.pagination {
	margin-top: 1rem;

	.page-numbers {
		background-color: #c4c4c4;
		display: inline-block;
		color: $color-white-1;
		font-family: $font-neutraface;
		font-size: 1.5rem;
		font-weight: 700;
		line-height: 3.6rem;
		width: 3.6rem;
		height: 3.6rem;
		margin-right: 1.2rem;

		@include breakpoint(767px down) {
			margin-top: 0.6rem;
			margin-bottom: 0.6rem;
		}

		&:last-child {
			margin-right: 0;
		}

		&.current {
			background-color: $color-black-1;
			color: $color-white-1;
		}
	}
}
