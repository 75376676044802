.column-content-l {
	$this: &;

	&__row {
		display: flex;
		flex-flow: row wrap;
		margin-left: -1.25rem;
		width: calc(100% + 2.5rem);

		@include breakpoint(809px down) {
			margin-left: 0;
			width: 100%;
		}
	}

	&__title {
		@extend %o-title-medium-28;
		flex: 1 1 0;
		color: $color-gray-4;
		line-height: 1.2;
		margin-bottom: 5rem;
		margin-top: 0;
		text-align: center;
	}

	&__col {
		flex: 0 1 auto;
		padding: 0 1.25rem;
		position: relative;
		width: 33.33%;

		&.d-block {
			display: block;
		}

		&.full {
			width: 100%;
		}

		@include breakpoint(1079px down) {
			margin-bottom: 2.4rem;
			width: 50%;
		}

		@include breakpoint(809px down) {
			padding: 0;
			width: 100%;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__col-box {
		@extend %background-image;
		display: flex;
		flex-flow: row wrap;
		align-items: flex-start;
		border-radius: 1rem;
		height: 100%;
		overflow: hidden;
		padding: 6rem 4rem 4rem;
		position: relative;

		&:before {
			background-color: rgba(21, 67, 68, 0.75);
			content: "";
			left: 0;
			height: 100%;
			position: absolute;
			top: 0;
			width: 100%;
		}

		.btn {
			flex: 0 1 auto;
			align-self: flex-end;
			position: relative;
		}
	}

	&__col-logo {
		left: 0;
		line-height: 1;
		position: absolute;
		text-align: center;
		top: 0;
		width: 100%;
		z-index: 2;
	}

	&__col-title {
		@extend %o-title-bold-24;
		color: $color-white-1;
		line-height: 1.2;
		margin-bottom: 2.5rem;
		margin-top: 0;
		position: relative;
	}

	&__link {
		margin-top: 5rem;
		text-align: center;

		@include breakpoint(1079px down) {
			margin-top: 2.6rem;
		}
	}

	&__loader {
		display: none;
		text-align: center;
	}

	&__col-message {
		@extend %o-title-bold-18;
		color: $color-gray-5;
		line-height: 1.2;
		margin-bottom: 0;
		text-align: center;
	}
}
