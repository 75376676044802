.featured-person-d {
	$this: &;

	overflow-x: hidden;

	&__row {
		margin-left: auto;
		margin-right: auto;

		&.max-width-1050 {
			max-width: 105rem;
		}
	}

	&__person {
		max-width: 30rem;

		@include breakpoint(1079px down) {
			margin-bottom: 5rem;
			margin-left: auto;
			margin-right: auto;
		}
	}

	&__person-content {
		@extend %background-image;
		position: relative;
		text-align: center;
		width: 100%;
		height: 100%;
	}

	&__person-img {
		@extend %background-image;
		border-radius: 100%;
		border: 1.6rem solid $color-white-1;
		box-shadow: 0.2rem 0.2rem 1.5rem 0 rgba(0, 0, 0, 0.25);
		max-width: 60%;
		padding-top: calc(60% - 3.2rem);
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 3rem;
		overflow: hidden;
	}

	&__person-name {
		@extend %o-title-bold-24;
		color: $color-gray-10;
		line-height: 1.2;
	}

	&__person-border {
		background-color: $color-green-1;
		width: 10.5rem;
		height: 0.3rem;
		margin: 1.5rem auto;
	}

	&__person-position {
		@extend %o-text-semibold-18;
		color: $color-gray-10;
		line-height: 1.3;
		margin: 0 auto 1.5rem;
		position: relative;
		width: 80%;
	}

	&__content {
		@include breakpoint(1080px up) {
			@include auto();
			padding-left: 11rem;
		}

		iframe {
			width: 100%;
		}
	}

	&__content-title {
		@extend %o-title-medium-54;
		color: $color-green-5;
		line-height: 1.2;
		margin-top: 0;
		margin-bottom: 1.5rem;
	}

	&__content-text {
		p,
		img {
			margin-bottom: 2rem;
		}

		p {
			color: $color-gray-3;
		}

		> * {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
