.elem-banner-d {
	$this: &;

	position: relative;

	&.content-left {
		#{$this}__media {
			right: 0;
		}

		#{$this}__content-d-cell {
			padding-right: 55%;

			@include breakpoint(809px down) {
				padding-right: 0;
			}
		}
	}

	&.content-right {
		#{$this}__media {
			left: 0;
		}

		#{$this}__content-d-cell {
			padding-left: 55%;
		}
	}

	&__media {
		width: 50%;
		height: 100%;
		position: absolute;
		top: 0;

		@include breakpoint(809px down) {
			display: none;
		}
	}

	&__img {
		@extend %background-image;
		width: 100%;
		height: 100%;
	}

	&__content-d {
		&.color-text-baby-powder {
			h1,
			h2 {
				color: $color-baby-powder;
			}

			p {
				color: $color-baby-powder;
			}
		}

		h1,
		h2 {
			@extend %o-title-bold-35;
			line-height: 1.2;
			letter-spacing: 1px;
			margin-bottom: 0;
		}
	}

	&__content-d-grid {
		padding-top: 12%;
		padding-bottom: 12%;
	}
}
