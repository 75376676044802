.content-d2 {
	$this: &;

	&.position-relative {
		position: relative;
	}

	@for $i from 2 through 3 {
		&.z-index-#{$i} {
			z-index: #{$i};
		}
	}

	&__content {
		position: relative;
		z-index: 1;

		> * {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__title {
		@extend %o-title-medium-54;
		color: $color-midnight-green;
		letter-spacing: 0;
		line-height: 1.2;
		margin-bottom: 2rem;

		&.color-text-charcoal {
			color: $color-charcoal;
		}

		&.color-text-onyx {
			color: $color-onyx;
		}

		&.color-text-white {
			color: $color-white-1;
		}

		&.font-size-32 {
			font-size: 3.2rem;
		}

		&.margin-center {
			margin-left: auto;
			margin-right: auto;
		}

		&.max-width-550 {
			max-width: 55rem;
		}

		&.max-width-600 {
			max-width: 60rem;
		}

		&.max-width-605 {
			max-width: 60.5rem;
		}

		&.max-width-700 {
			max-width: 70rem;
		}

		&.max-width-800 {
			max-width: 80rem;
		}

		&.max-width-940 {
			max-width: 94rem;
		}
	}

	&__border {
		&.gap-20 {
			margin-top: 2rem;
			margin-bottom: 2rem;
		}

		&.height-3 {
			height: 0.3rem;
		}

		&.margin-center {
			margin-left: auto;
			margin-right: auto;
		}

		&.max-width-105 {
			max-width: 10.5rem;
		}
	}

	&__text {
		&.color-text-charcoal {
			h1,
			h2,
			h3,
			h4,
			h5,
			h6,
			p,
			li {
				color: $color-charcoal;
			}
		}

		&.color-text-onyx {
			h1,
			h2,
			h3,
			h4,
			h5,
			h6,
			p,
			li {
				color: $color-onyx;
			}
		}

		&.color-text-white {
			h1,
			h2,
			h3,
			h4,
			h5,
			h6,
			p,
			li {
				color: $color-white-1;
			}
		}

		&.font-size-22 {
			p,
			li {
				font-size: 2.2rem;
				line-height: 1.5;
			}
		}

		&.font-size-30 {
			p,
			li {
				font-size: 3rem;
				line-height: 1.25;

				@include breakpoint(809px down) {
					font-size: 1.6rem;
				}
			}
		}

		&.font-weight-medium {
			font-weight: 500;
		}

		&.margin-center {
			margin-left: auto;
			margin-right: auto;
		}

		&.max-width-400 {
			max-width: 40rem;
		}

		&.max-width-450 {
			max-width: 45rem;
		}

		&.max-width-550 {
			max-width: 55rem;
		}

		&.max-width-605 {
			max-width: 60.5rem;
		}

		&.max-width-700 {
			max-width: 70rem;
		}

		&.max-width-840 {
			max-width: 84rem;
		}

		&.max-width-900 {
			max-width: 90rem;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		p,
		li {
			color: $color-midnight-green;
		}

		p,
		li {
			color: $color-midnight-green;
			font-size: 1.6rem;
			line-height: 1.5;
		}

		ul {
			padding-left: 2rem;

			li {
				list-style-position: outside;
			}
		}

		.button-box {
			margin-top: 2.5rem;
		}
	}
}
