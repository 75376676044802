.o-cc-ca-cgti {
	$this: &;

	border-radius: 1.5rem;
	box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.25);
	padding: 2rem 2rem 0;
	height: 100%;

	&.col-gap-20 {
		$gap: 1rem;

		#{$this}__row {
			margin-left: -$gap;
			margin-right: -$gap;

			@include breakpoint(767px down) {
				margin-left: 0;
				margin-right: 0;
			}
		}

		#{$this}__col {
			padding-left: $gap;
			padding-right: $gap;
			margin-bottom: #{$gap * 2};

			@include breakpoint(767px down) {
				padding-left: 0;
				padding-right: 0;
				margin-bottom: 1rem;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	&__title {
		@extend %o-title-semibold-24;
		color: $color-teal-1;
		line-height: 1;
		margin-bottom: 1rem;
	}

	&__col {
		&.col-25 {
			width: 25%;

			@include breakpoint(767px down) {
				width: 100%;
			}
		}

		&.col-33 {
			width: 33.3333%;

			@include breakpoint(767px down) {
				width: 100%;
			}
		}

		&.col-50 {
			width: 50%;

			@include breakpoint(767px down) {
				width: 100%;
			}
		}
	}

	&__col-title {
		@extend %o-title-book-24;
		color: $color-black-1;
		line-height: 1;
		margin-bottom: 2rem;
	}
}
