.o-cc-ca-c1 {
	border-radius: 1.5rem;
	padding: 2rem;
	height: 100%;

	&__top {
		margin-bottom: 2rem;
	}

	&__icon {
		padding-right: 2rem;

		img {
			width: 4rem;
		}
	}

	&__title {
		@extend %o-title-semibold-24;
		color: $color-onyx;
		line-height: 1;
		margin-bottom: 0;
	}
}
