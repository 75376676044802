.o-bullet-green-check {
	margin-bottom: 2rem;

	&:last-child {
		margin-bottom: 0;	
	}	

	ul {
		margin-left: 0;
		margin-bottom: 0;

		li {
			list-style: none;
			margin-bottom: 1.5rem;
			padding-left: 2.8rem;
			position: relative;

			&:last-child {
				margin-bottom: 0;
			}

			&:before {
				background-image: url("../../assets/images/green-check-icon.png");
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				content: "";
				height: 2rem;
				left: 0;
				position: absolute;
				top: 50%;
				width: 2rem;
				transform: translateY(-50%);
			}
		}
	}
}
