.column-content-t {
	$this: &;

	&.col-3 {
		#{$this}__col {
			@include breakpoint(1080px up) {
				width: 33.3333%;
			}
		}
	}

	&__row {
		$gap: 1.5rem;

		margin-left: -$gap;
		margin-right: -$gap;

		@include breakpoint(1079px down) {
			display: none;
			margin-left: 0;
			margin-right: 0;
		}
	}

	&__col {
		$gap: 1.5rem;

		padding-left: 1.5rem;
		padding-right: 1.5rem;

		@include breakpoint(1079px down) {
			margin-bottom: 3rem;
		}

		@include breakpoint(809px down) {
			width: 100%;
		}

		&:last-child {
			margin-bottom: 0;
		}

		.o-cc-t-d {
			height: 100%;
			position: relative;

			&__user {
				min-height: 8rem;

				@include breakpoint(809px down) {
					min-height: auto;
				}
			}
		}
	}

	&__slider {
		padding-left: 5rem;
		padding-right: 5rem;
		position: relative;

		@include breakpoint(1080px up) {
			display: none;
		}

		@include breakpoint(809px down) {
			padding-left: 3.5rem;
			padding-right: 3.5rem;
		}
	}

	&__swiper {
		padding-bottom: 3.8rem;
	}

	&__swiper-slide {
		height: auto;

		.o-cc-t-d {
			box-sizing: border-box;
			height: 100%;
		}
	}

	&__swiper-pagination.swiper-pagination {
		bottom: 0;

		.swiper-pagination-bullet {
			background-color: $color-white-1;
			width: 1rem;
			height: 1rem;
		}
	}

	&__swiper-button-next,
	&__swiper-button-prev {
		margin-top: -3.8rem;

		&::after {
			color: $color-white-1;

			@include breakpoint(809px down) {
				font-size: 2.4rem;
			}
		}
	}

	&__swiper-button-next {
		right: 0;
	}

	&__swiper-button-prev {
		left: 0;
	}
}
