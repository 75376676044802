.column-content-fc {
	$this: &;
	$gap: 2.5rem;
	$gap_m: 0;

	position: relative;

	&__bg {
		@extend %background-image;
		height: 100%;
		left: 0;
		pointer-events: none;
		position: absolute;
		top: 0;
		width: 100%;

		&.bg-img {
			top: 20rem;
		}
	}

	&__grid {
		position: relative;
		z-index: 2;
	}

	&--icon-and-text,
	&--banner-cc {
		.o-card-fc {
			box-shadow: none;

			&__icon {
				margin: 0 auto 2.4rem;
				position: relative;
				display: block;

				img {
					@include breakpoint(1081) {
						height: 6rem;
					}

					@include breakpoint(809 down) {
						height: 4rem;
					}

					@include breakpoint(479 down) {
						height: 3rem;
					}
				}
			}

			&__content {
				padding-bottom: 0;
			}
		}
	}

	&--small {
		.o-card-fc__title {
			font-family: $font-neutraface;
			font-weight: 500;
			letter-spacing: 2px;
			font-weight: normal;
			line-height: 1.5;
		}

		.o-card-fc__text p,
		.o-card-fc__text li {
			font-family: $font-neutraface;
		}

		.o-card-fc__content {
			padding: 4rem 2.4rem;
		}

		.o-card-fc__icon {
			margin: unset;
			margin-bottom: 2.4rem;
		}
	}

	&--block {
		$trans: 0.4s ease 0s;

		text-align: center;

		.o-card-fc {
			&.onhover {
				&:hover {
					.o-card-fc__icon {
						> img {
							opacity: 0;
						}
					}
	
					.o-card-fc__icon-hover {
						opacity: 1;
					}
				}
			}
		}

		.o-card-fc__top {
			&.add-gap {
				.o-card-fc__title {
					padding-left: 0;
				}
			}
		}

		.o-card-fc__icon,
		.o-card-fc__title {
			max-width: 100%;
			width: 100%;
		}

		.o-card-fc__icon {
			position: relative;
			margin-bottom: 1.5rem;

			> img {
				opacity: 1;
				transition: opacity $trans;
			}
		}

		.o-card-fc__icon-hover {
			opacity: 0;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			transition: opacity $trans;
		}

		.o-card-fc__title {
			font-size: 2.4rem;
		}
	}

	&__row {
		margin-bottom: 3.6rem;
		margin-left: #{$gap * -1};
		margin-right: #{$gap * -1};

		&:last-child {
			margin-bottom: 0;
		}

		@include breakpoint(mediumLow down) {
			margin-left: #{$gap_m * -1};
			margin-right: #{$gap_m * -1};
			justify-content: center;
		}
	}

	&__section-title {
		color: $color-black-1;
		font-family: $font-neutraface;
		font-weight: 500;
		line-height: 1.25;
		margin-top: 0;
		margin-bottom: 0;
		text-transform: capitalize;

		@include breakpoint(small) {
			margin-bottom: 3.5rem;
			font-size: 2.8rem;
		}

		@include breakpoint(large) {
			margin-bottom: 3.5rem;
			font-size: 3.8rem;
		}

		#{$this}--boxed & {
			margin-bottom: 10rem;
		}

		#{$this}--boxed#{$this}--small & {
			margin-bottom: 4rem;
		}
	}

	&__item {
		padding-left: #{$gap * 1};
		padding-right: #{$gap * 1};
		width: auto;

		@include breakpoint(medium down) {
			width: 50%;
		}

		@include breakpoint(small only) {
			width: 100%;
		}

		@include breakpoint(large) {
			justify-content: unset;
		}

		#{$this}--boxed & {
			height: 100%;

			@include breakpoint(large down) {
				margin-bottom: 8rem;
			}
		}

		#{$this}--boxed#{$this}--small & {
			@include breakpoint(large down) {
				margin-bottom: 3rem;
			}
		}

		&--border-green {
			border-top: 1rem solid $color-green-1;
		}

		#{$this}--boxed & {
			@include breakpoint(large) {
				width: 33.3333%;
			}
		}

		@include breakpoint(mediumLow down) {
			padding-left: #{$gap_m * 1};
			padding-right: #{$gap_m * 1};
		}

		&:last-child {
			margin-bottom: 0;
		}

		.o-card-fc {
			height: 100%;
			box-shadow: none;
			background-color: transparent;

			#{$this}--boxed & {
				box-shadow: 0 0.1rem 1.5rem rgba(0, 0, 0, 0.1);
				background-color: $color-white-1;
			}
		}

		.o-card-fc__content {
			padding: 4rem 0;
			position: relative;
		}

		.o-card-fc__top {
			&.add-gap {
				.o-card-fc__title {
					padding-left: 1rem;
				}
			}
		}

		.o-card-fc__title {
			font-size: 1.8rem;

			#{$this}--boxed & {
				font-size: 2rem;
			}

			#{$this}--block & {
				font-size: 2.4rem;
			}
		}

		.o-card-fc__text {
			display: flex;
			flex-flow: row wrap;
			justify-content: center;
			margin-top: 1.6rem;

			.button-box {
				flex: 0 1 auto;
				align-self: flex-end;
				margin-top: 2rem;
				width: 100%;

				.button {
					display: block;
					padding: 1.8rem;
				}
			}
		}

		.o-card-fc__text p,
		.o-card-fc__text li {
			font-family: $font-neutraface;
			font-size: 1.6rem;
			color: $color-black-1;
		}

		.o-card-fc__text ul {
			padding-left: 2.5rem;
		}

		.o-card-fc__text li {
			font-family: $font-neutraface;
			font-weight: 500;
			color: $color-black-1;
			list-style: disc;
			line-height: 4rem;
			margin-bottom: 0;
		}
	}

	&__swiper {
		@include breakpoint(810px up) {
			display: none;
		}

		.swiper-button-prev,
		.swiper-button-next {
			top: auto;
			bottom: 20%;

			@include breakpoint(600px down) {
				bottom: 24%;
			}

			@include breakpoint(479px down) {
				bottom: 30%;
			}
		}

		.swiper-button-prev {
			left: 0;
		}

		.swiper-button-next {
			right: 0;
		}
	}

	.btn-cta,
	.button {
		padding: 1.8rem 7.75rem;
	}

	&#{$this}--boxed {
		.o-card-fc {
			padding-left: 4rem;
			padding-right: 4rem;
		}

		.o-card-fc__icon {
			margin-top: -7.5rem;
			margin-bottom: 3rem;
		}
	}

	&#{$this}--small {
		.o-card-fc__icon {
			margin-top: 0;
			margin-bottom: 2.4rem;
		}
	}
}
