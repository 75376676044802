.accordion-d {
	$this: &;

	&__cell {
		max-width: 80rem;
	}

	&__item {
		border-bottom: 0.1rem solid $color-gray-10;
		padding: 1.5rem 0;

		&:last-child {
			border-bottom: 0;
			padding-bottom: 0;
		}

		&.collapsed {
			#{$this}__item-symbol {
				svg {
					transform: rotate(180deg);
				}
			}
		}
	}

	&__item-title {
		@extend %o-title-semibold-24;
		color: $color-onyx;
		cursor: pointer;
		line-height: 1.2;
		padding-right: 6rem;
		position: relative;

		@include breakpoint(809px down) {
			font-size: 1.8rem;
		}

		&.color-text-green {
			color: $color-green-5;
		}
	}

	&__item-symbol {
		position: absolute;
		right: 2rem;
		top: -0.3rem;
	}

	&__item-content {
		display: none;
		padding-top: 1.5rem;

		ul,
		ol {
			margin-bottom: 1.5rem;
			margin-left: 1.5rem;

			li {
				margin-bottom: 1rem;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		ul {
			li {
				list-style: none;
				padding-left: 1.5rem;
				position: relative;

				&:before {
					background-color: $color-gray-10;
					border-radius: 100%;
					content: "";
					height: 0.5rem;
					left: 0;
					position: absolute;
					top: 0.8rem;
					width: 0.5rem;
				}
			}
		}

		> * {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
