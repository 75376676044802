.tab-content-d {
	$this: &;
	$trans: 0.4s ease 0s;

	&__header {
		margin-bottom: 5rem;

		@include breakpoint(1024px down) {
			margin-bottom: 3rem;
		}
	}

	&__header-wrap {
		background-color: #ffffff;
		border: 1px solid #cccdcd;
		border-radius: 3rem;
		padding: 5px;

		@include breakpoint(1080 up) {
			@include shrink();
		}

		@include breakpoint(1079 down) {
			background-color: transparent;
			border: 0;
		}
	}

	&__header-item {
		$trans: 0.3s ease 0s;

		border-radius: 3rem;
		position: relative;

		@include breakpoint(1025px up) {
			@include shrink();
			margin-right: 1rem;

			&:last-child {
				margin-right: 0;
			}
		}

		@include breakpoint(1024px down) {
			margin-bottom: 1rem;

			&:last-child {
				margin-bottom: 0;
			}
		}

		@include breakpoint(767 down) {
			margin-bottom: 2.3rem;
		}

		&::after {
			background: linear-gradient(
				to right,
				#ffffff 0%,
				#ffffff 50%,
				#ffffff 100%
			);
			display: block;
			content: "";
			border-radius: 10rem;
			position: absolute;
			top: -2px;
			bottom: -2px;
			left: -2px;
			right: -2px;
			z-index: 1;
			transition: background-color $trans;
		}

		> span {
			background-color: #ffffff;
			border-radius: 3rem;
			min-width: 19.5rem;
			padding: 0.3rem 2.5rem;
			cursor: pointer;
			text-align: center;
			text-decoration: none;
			height: 100%;
			position: relative;
			z-index: 2;
			transition: background-color $trans, border-color $trans, color $trans;

			@include breakpoint(1024px down) {
				min-height: 5rem;
			}

			img {
				@include shrink();
				margin-right: 1rem;
			}

			span {
				@include shrink();
				color: #303030;
				font-family: $font-neutraface;
				font-size: 1.8rem;
				font-weight: 600;
				line-height: 1.2;

				@include breakpoint(1080 down) {
					font-size: 1.3rem;
				}
			}
		}

		&.active,
		&:hover {
			&::after {
				background: linear-gradient(
					to right,
					#00bc00 0%,
					#00808c 50%,
					#f0de30 100%
				);
			}
		}
	}

	&__tab-item {
		display: none;

		&.active {
			display: block;
		}
	}

	&__pc {
		&.content-max-width-480 {
			@include breakpoint(1024px up) {
				#{$this}__pc-content {
					max-width: 48rem;
				}

				#{$this}__pc-media {
					@include auto;
				}
			}
		}

		&.content-left {
			@include breakpoint(1024px up) {
				#{$this}__pc-content {
					padding-right: 5rem;
					order: 1;
				}

				#{$this}__pc-media {
					order: 2;
				}
			}

			@include breakpoint(1023 down) {
				margin-bottom: 3rem;
			}
		}

		&.content-right {
			@include breakpoint(1024px up) {
				#{$this}__pc-content {
					padding-left: 5rem;
				}
			}

			@include breakpoint(767) {
				margin-bottom: 3rem;
			}
		}

		&.mobile-hide-media {
			@include breakpoint(767px down) {
				#{$this}__pc-media {
					display: none;
				}
			}
		}
	}

	&__pc-content {
		@include breakpoint(1079 down) {
			margin-bottom: 3rem;
			text-align: center;
		}
	}

	&__pc-text {
		.button-box {
			margin-top: 1.5rem;

			&:first-child {
				margin-top: 0;
			}
		}
	}

	&__pc-media {
		@include breakpoint(1023 down) {
			margin-bottom: 3rem;
			order: 2;
			margin-bottom: 0;
		}
	}

	&__pc-embed {
		overflow: hidden;
		position: relative;

		&.border-radius-15 {
			border-radius: 1.5rem;
		}

		iframe,
		video {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
		}
	}

	&__pc-if {
		position: relative;

		&.active {
			#{$this}__pc-if-overlay {
				z-index: 1;

				div {
					display: none;
				}
			}

			#{$this}__pc-if-video {
				z-index: 2;
			}
		}
	}

	&__pc-if-overlay {
		@extend %background-image;
		cursor: pointer;
		position: relative;
		z-index: 2;

		> div {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	&__pc-if-video {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;

		iframe,
		video {
			width: 100%;
			height: 100%;
		}
	}

	&__pc-img {
		width: 100%;
		height: 100%;
		
		@include breakpoint(1023px down) {
			text-align: center;
		}
	}

	&__pc-title {
		@extend %o-title-semibold-40;
		color: $color-gray-13;
		margin-bottom: 0;

		@include breakpoint(767 down) {
			text-align: center;
		}

		&.text-transform-uppercase {
			text-transform: uppercase;
		}
	}

	&__pc-title-container {
		margin-bottom: 1rem;

		@include breakpoint(1079 down) {
			justify-content: center;
		}

		#{$this}__pc-title,
		img {
			align-self: center;
		}

		img {
			margin-right: 0.5rem;
		}
	}
}
