.two-column-content-d {
	$this: &;

	position: relative;

	&__row {
		$gap: 2.5rem;

		margin-left: -$gap;
		margin-right: -$gap;

		@include breakpoint(1079px down) {
			margin-left: 0;
			margin-right: 0;
		}
	}

	&__col {
		$gap: 2.5rem;

		padding-left: $gap;
		padding-right: $gap;

		@include breakpoint(1079px down) {
			padding-left: 0;
			padding-right: 0;
			margin-bottom: 3rem;
		}

		&:last-child {
			margin-bottom: 0;
		}

		&.mobile-order-1 {
			@include breakpoint(1079px down) {
				order: 1;
				margin-bottom: 3rem;
			}
		}

		&.mobile-order-2 {
			@include breakpoint(1079px down) {
				order: 2;
				margin-bottom: 0;
			}
		}

		&.width-55p {
			@include breakpoint(1080px up) {
				width: 55%;
			}
		}

		&.width-50p {
			@include breakpoint(1080px up) {
				width: 50%;
			}
		}

		&.width-45p {
			@include breakpoint(1080px up) {
				width: 45%;
			}
		}

		&.width-35p {
			@include breakpoint(1080px up) {
				width: 35%;
			}
		}
	}

	&__content {
		margin-bottom: 3rem;

		&.color-text-white {
			h1,
			h2,
			h3,
			h4,
			h5,
			h6,
			p {
				color: $color-white-1;
			}

			ol,
			ul {
				li {
					color: $color-white-1;
				}
			}
		}

		&.font-size-18 {
			li,
			p {
				font-size: 1.8rem;
			}
		}

		&.form-style-free-trial {
			h3 {
				@include breakpoint(1080 down) {
					font-size: 2rem;
				}
			}

			&.color-text-white {
				.hs-richtext {
					&.hs-main-font-element {
						> * {
							color: $color-white-1;
						}
					}
				}

				.hs-form-field {
					> label {
						color: $color-white-1;
					}

					input {
						&::placeholder {
							color: $color-gray-10;
						}
					}
				}
			}

			.hbspt-form {
				background-color: transparent;
				border-radius: 0;
				padding: 0;

				.submitted-message {
					color: $color-green-1;
					font-size: 1.8rem;

					@include breakpoint(767 down) {
						font-size: 1.6rem;
					}
				}
			}

			// FORM COLUMNS
			.form-columns-1 {
				.hs-form-field {
					input,
					textarea {
						width: 100% !important;
					}
				}
			}

			.form-columns-2 {
				.hs-form-field {
					width: 100%;
					padding-left: 0;
					padding-right: 0;
				}
			}

			// MAIN TITLE
			.hs-richtext {
				&.hs-main-font-element {
					> * {
						font-family: $font-neutraface !important;
						font-size: 2.2rem !important;
						font-weight: 600 !important;
						line-height: 1.3;
						margin-bottom: 1.2rem !important;

						span {
							color: inherit !important;
							font: inherit !important;
							font-size: 2.7rem !important;
						}
					}
				}
			}

			// FIELDS
			.hs-form-field {
				display: flex;
				flex-flow: row wrap;
				align-items: center;
				margin-bottom: 1.8rem;

				> label {
					@include cell();
					@include shrink();
					// width: 18rem;

					span {
						font-size: 1.8rem;
						font-weight: 500;
						line-height: 1;

						&.hs-form-required {
							color: #f00;
						}
					}
				}

				.input {
					@include cell();
					@include auto();
				}

				input,
				textarea {
					background-color: #fff;
					border: 1px solid rgba(48, 48, 48, 0.3);
					border-radius: 2.4rem;
					box-shadow: 0 0 0 transparent;
					font-family: $font-neutraface;
					font-size: 1.8rem;
					margin-bottom: 0;
					width: 100%;

					&:focus {
						box-shadow: 0 0 0 transparent;
					}

					@each $prefix in $placeholder_prefixes {
						&#{$prefix} {
							color: inherit;
							font: inherit;
							opacity: 1;

							&:focus {
								box-shadow: 0 0 0 transparent;
							}
						}
					}
				}

				textarea {
					min-height: 15rem;
				}

				// FIELD SUBMISSION ERROR
				.inputs-list {
					@include cell();
					margin-top: 1rem;
					margin-bottom: 1rem;
					padding-left: 9.5rem;

					@include breakpoint(767 down) {
						padding-left: 0;
					}

					label {
						color: pink;
						font-size: 1.6rem;

						@include breakpoint(767 down) {
							font-size: 1.4rem;
							margin-bottom: 0;
							text-align: center;
							width: 100%;
						}
					}

					&.hs-error-msgs {
						padding-left: 0;
					}
				}
			}

			// SUBMISSION ERROR
			.hs_error_rollup {
				ul {
					margin-top: 1rem;
					margin-bottom: 1rem;
					padding-left: 9.5rem;

					@include breakpoint(767 down) {
						padding-left: 0;
					}

					label {
						color: pink;
						font-size: 1.6rem;

						@include breakpoint(767 down) {
							font-size: 1.4rem;
							margin-bottom: 0;
							text-align: center;
							width: 100%;
						}
					}

					&.hs-error-msgs {
						padding-left: 0;
					}
				}
			}

			.hs-error-msgs {
				padding-left: 0;
			}

			// SUBMIT BUTTON
			.hs-submit {
				$trans: 0.3s ease 0s;

				text-align: right;

				@include breakpoint(1079px down) {
					text-align: center;
				}

				input {
					background-color: $color-teal-3;
					border: 2px solid $color-teal-1;
					border-radius: 10rem;
					box-sizing: border-box;
					color: $color-white-1;
					cursor: pointer;
					display: inline-block;
					font-family: $font-neutraface;
					font-size: 1.6rem;
					font-weight: 600;
					line-height: 1.2;
					min-width: 19.5rem;
					padding: 1.5rem 3.5rem;
					text-align: center;
					text-decoration: none;
					text-transform: uppercase;
					transition: background-color $trans, border-color $trans, color $trans;

					&:hover {
						background-color: $color-teal-1;
						border-color: $color-teal-3;
						text-decoration: none;
					}
				}
			}
		}

		&.form-style-free-trial-left {
			@include breakpoint(1024) {
				padding-right: 10.5rem;
				position: relative;
				max-width: 50rem;

				&::before {
					background-color: #fff;
					display: block;
					content: "";
					width: 1px;
					height: 120%;
					position: absolute;
					top: 54%;
					right: 0;
					transform: translateY(-50%);
				}
			}

			@include breakpoint(1023 down) {
				border-top: 1px solid $color-white-1;
				padding-top: 3rem;
			}
		}

		&.form-style-free-trial-right {
			@include breakpoint(1024) {
				padding-left: 10.5rem;
				position: relative;
				max-width: 50rem;

				&::before {
					background-color: #fff;
					display: block;
					content: "";
					width: 1px;
					height: 120%;
					position: absolute;
					top: 54%;
					left: 0;
					transform: translateY(-50%);
				}
			}

			@include breakpoint(1023 down) {
				border-top: 1px solid $color-white-1;
				padding-top: 3rem;
			}
		}

		h1 {
			font-size: 3.6rem;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin-bottom: 3rem;
		}

		iframe {
			max-width: 100%;
		}

		p {
			color: $color-white-1;
			font-size: 2.4rem;
			line-height: 1.25;
		}

		ol,
		ul {
			margin-left: 2.1rem;
			margin-bottom: 3rem;

			li {
				font-size: 2.2rem;
				list-style: disc;
			}
		}

		> * {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__border {
		&.color-default {
			background: linear-gradient(90deg, rgb(0, 188, 0) 0%, rgb(240, 222, 48) 100%);
		}

		&.width-105 {
			width: 10.5rem;
		}

		&.height-3 {
			height: 3px;
		}

		&.margin-top-30 {
			margin-top: 3rem;
		}

		&.margin-bottom-30 {
			margin-bottom: 3rem;
		}

		&.margin-center {
			margin-left: auto;
			margin-right: auto;
		}

		&.margin-left-auto {
			margin-left: auto;
		}

		&.margin-right-auto {
			margin-right: auto;
		}
	}

	&__img {
		margin-bottom: 3rem;

		&:last-child {
			margin-bottom: 0;
		}

		@include breakpoint(1079px down) {
			text-align: center;
		}
	}

	&__title {
		@extend %o-title-regular-36;
		color: $color-green-1;
		line-height: 1.2;
		margin-bottom: 2.5rem;

		&.color-text-white {
			color: $color-white-1;
		}

		&.text-transform-uppercase {
			text-transform: uppercase;
		}
	}
}
