.careers-single {
	$this: &;

	&.col-gap-80 {
		$gap: 4rem;

		#{$this}__row {
			@include breakpoint(768px up) {
				margin-left: -$gap;
				margin-right: -$gap;
			}
		}

		#{$this}__col {
			margin-bottom: #{$gap * 2};

			@include breakpoint(768px up) {
				padding-left: $gap;
				padding-right: $gap;
			}

			@include breakpoint(767px down) {
				margin-bottom: 2rem;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	&__col {
		&.sidebar {
			padding-left: 0 !important;
			padding-right: 3rem !important;

			@include breakpoint(768px up) {
				@include shrink();
			}

			@include breakpoint(1080px up) {
				max-width: 28rem;
			}

			@media screen and (min-width: 768px) and (max-width: 1079px) {
				@include shrink();
				max-width: 20rem;
			}

			@include breakpoint(767px down) {
				max-width: none;
				padding-right: 0 !important;
			}
		}

		&.desc {
			padding-right: 0 !important;

			@include breakpoint(768px up) {
				@include auto();
			}

			@include breakpoint(767px down) {
				border-top: 1px solid $color-black-1;
				padding-top: 3.5rem;
			}
		}
	}

	&__side {
		@include breakpoint(767px down) {
			text-align: center;
			max-width: 25rem;
			margin-left: auto;
			margin-right: auto;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: #00808c;
			font-weight: 400;
			margin-bottom: 3rem;

			@include breakpoint(1079px down) {
				font-size: 1.8rem;
				margin-bottom: 2rem;
			}

			@include breakpoint(767px down) {
				margin-bottom: 1.5rem;
			}
		}

		h3 {
			font-size: 2.8rem;
		}

		ul {
			margin-left: 0;
			margin-bottom: 0;

			li {
				list-style-type: none;
				font-size: 2.2rem;
				line-height: 1.25;
				padding-top: 3.5rem;
				padding-bottom: 3.5rem;
				margin-bottom: 0;

				@include breakpoint(1079px down) {
					font-size: 1.8rem;
					padding-top: 2rem;
					padding-bottom: 2rem;
				}

				@include breakpoint(767px down) {
					padding-top: 1.5rem;
					padding-bottom: 1.5rem;
				}

				&:first-child {
					padding-top: 0;
				}

				&:last-child {
					border-bottom: 0 none;
					padding-bottom: 0;
				}

				a {
					color: inherit;
					font: inherit;
					text-decoration: underline;
					text-decoration-thickness: 1px;
				}
			}
		}
	}

	&__side-border {
		background: linear-gradient(
			90deg,
			rgb(0, 188, 0) 0%,
			rgb(240, 222, 48) 100%
		);
		height: 3px;

		&:last-child {
			background: none;
		}
	}

	&__text {
		h3 {
			font-weight: 400;
		}

		ul {
			margin-bottom: 2rem;
			margin-left: 0;

			&:last-child {
				margin-bottom: 0;
			}

			li {
				list-style-type: none;
				padding-left: 3.5rem;
				margin-bottom: 0.5rem;
				position: relative;

				&::before {
					background-color: $color-gunmetal-1;
					border-radius: 50%;
					display: block;
					content: "";
					padding: 2px;
					position: absolute;
					top: 1.2rem;
					left: 1.3rem;
				}
			}
		}

		li,
		p {
			margin-bottom: 2rem;

			&:last-child {
				margin-bottom: 0;
			}
		}

		em {
			color: inherit;
			font: inherit;
			font-style: italic;
			font-weight: 300;
		}
	}

	&__border {
		background: linear-gradient(
			90deg,
			rgb(0, 188, 0) 0%,
			rgb(240, 222, 48) 100%
		);
		width: 8rem;
		height: 3px;
		margin-top: 2rem;
		margin-bottom: 2rem;
	}
}
