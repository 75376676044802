.footer {
	$this: &;
	background-color: $color-white-1;

	p {
		color: $color-gray-1;
		font-size: 2.4rem;

		a {
			color: $color-green-1;

			&:hover {
				color: $color-gray-1;
			}
		}
	}

	&__bottom-grid {
		text-align: center;
	}

	> #{$this}__grid {
		padding: 9rem 0 4rem;

		@include breakpoint(1079px down) {
			padding: 8rem 0;
		}

		@include breakpoint(809px down) {
			padding: 5rem 0;
		}

		@include breakpoint(767 down) {
			padding: 3rem 0;
		}
	}

	&__bottom {
		border-top: 1px solid $color-gray-3;
		padding-top: 1.8rem;
	}

	&__bottom-copyright {
		@include breakpoint(1080 down) {
			margin-top: 3rem;
		}

		@include breakpoint(1079 down) {
			flex: 0 0 auto;
			margin-top: 1rem;
			min-height: 0;
			min-width: 0;
			text-align: center;
			width: 100%;
		}

		p {
			font-size: 1.6rem;

			@include breakpoint(767 down) {
				font-size: 1rem;
			}
		}
	}

	&__bottom-nav {
		@include breakpoint(1079 down) {
			flex: 0 0 auto;
			min-height: 0;
			min-width: 0;
			width: 100%;
		}

		ul {
			margin: 0;
			padding: 0;

			@include breakpoint(1079 down) {
				text-align: center;
				width: 100%;
			}

			li {
				list-style: none;
				display: inline;
				margin: 1rem 0 0 0;
				border-width: 0;
				border-right-width: 1px;
				border-style: solid;
				border-color: $color-gray-3;

				&:last-child {
					border-right-width: 0;
				}

				&:first-child {
					a {
						padding-left: 0;
					}
				}
			}

			a {
				color: $color-gray-1;
				padding: 0.5rem 2rem;
				text-decoration: none;
				font-size: 1.6rem;
				vertical-align: middle;

				@include breakpoint(809 down) {
					padding: 0.5rem 0.5rem 0.5rem;
					font-size: 1rem;
				}

				&:hover {
					color: $color-green-2;
				}
			}
		}
	}

	&__menu {
		padding-left: 9.3rem;

		@include breakpoint(1080px up) {
			@include auto();
		}

		@include breakpoint(1080px down) {
			padding-left: 0;
		}

		@include breakpoint(1079px down) {
			margin-bottom: 5rem;
			order: 1;
			padding-right: 0;
		}

		@include breakpoint(809px down) {
			margin-bottom: 2rem;
		}
	}

	&__menu-items {
		$gap: 5rem;
		$gap_1023: 2.5rem;
		$gap_809: 1rem;

		@include breakpoint(1080) {
			padding-left: 6rem;
		}

		@include breakpoint(1079 down) {
			justify-content: center;
			text-align: center;
		}

		> ul {
			list-style: none;
			margin: 0;

			@include breakpoint(1079 down) {
				margin-bottom: 3rem;
				justify-content: center;
			}
		}

		&.mobile {
			padding-left: 0;

			li {
				:first-child {
					padding-left: 0;
				}

				&:last-child {
					padding-right: 0;
				}
			}
		}
	}

	&__menu-item {
		$gap: 3rem;
		$gap_1023: 2.5rem;
		$gap_809: 1rem;

		margin-bottom: 0;
		padding-left: $gap;
		padding-right: $gap;
		width: 25%;

		@include breakpoint(1079px down) {
			margin-bottom: 4rem;
			padding-left: $gap_1023;
			padding-right: $gap_1023;
			width: 50%;
		}

		&:first-child {
			padding-left: 0;
		}

		&:last-child {
			padding-right: 0;
		}

		> a {
			color: $color-gray-10;
			font-family: $font-neutraface;
			font-size: 1.7rem;
			font-weight: 700;
			letter-spacing: -0.05rem;
			line-height: 1;
			opacity: 1;
			transition: all 0.3s ease;

			&:hover {
				opacity: 0.5;
				text-decoration: none;
			}
		}
	}

	&__submenu {
		list-style: none;
		margin: 0;
		margin-top: 2.5rem;

		> li {
			margin-bottom: 1.5rem;

			&:last-child {
				margin-bottom: 0;
			}

			> a {
				color: $color-gray-1;
				font-family: $font-neutraface;
				font-size: 1.4rem;
				font-weight: 400;
				line-height: 1;
				opacity: 1;
				transition: all 0.3s ease;

				&:hover {
					opacity: 0.5;
					text-decoration: none;
				}
			}
		}
	}

	&__content {
		margin-bottom: 2.5rem;

		@include breakpoint(1080 up) {
			@include shrink();
		}

		@include breakpoint(1079 down) {
			text-align: center;
			order: 2;
		}

		.btn {
			min-width: 19.5rem;
			text-align: center;
		}
	}

	&__address {
		margin-bottom: 2.5rem;
		max-width: 25rem;

		@include breakpoint(1079 down) {
			margin-left: auto;
			margin-right: auto;
			text-align: center;
		}

		p {
			color: $color-night-2;
			font-size: 2.4rem;
			font-weight: 500;
			line-height: 1.2;

			@include breakpoint(1079 down) {
				margin-bottom: 3rem;
			}

			@include breakpoint(809 down) {
				font-size: 2.2rem;
			}
		}
	}

	&__newsletter {
		.hbspt-form {
			padding: 0;

			.hs-submit {
				display: none;
			}

			.hs-error-msg {
				color: red;
				font-weight: 700;
			}

			.submitted-message {
				color: green;
				font-weight: 700;
			}

			ul li {
				list-style-type: none;
			}

			.hs_error_rollup {
				display: none;
			}
		}

		.hs-form-field {
			> label {
				display: none;
			}

			input {
				border: 2px solid $color-gray-17;
				border-radius: 100rem;
				box-shadow: none;
				font-size: 1.6rem;
				padding: 2rem;
				line-height: 1;

				&::placeholder {
					color: $color-gray-10;
				}

				@include breakpoint(1079 down) {
					text-align: center;
					width: 30rem !important;
					margin-left: auto;
					margin-right: auto;
				}
			}
		}

		h3 {
			font-size: 2.4rem;
			line-height: 1.2;
			margin-bottom: 7px;
		}

		p {
			font-size: 1.4rem;
			line-height: 1;
		}
	}

	&__social {
		display: flex;
		flex-flow: row wrap;
		list-style: none;
		margin: 0;

		@include breakpoint(1079 down) {
			justify-content: center;
		}

		> li {
			flex: 0 1 auto;
			margin-bottom: 0;
			margin-right: 1.5rem;

			&:last-child {
				margin-right: 0;
			}

			a {
				color: $color-black-1;
				display: block;
				font-size: 3.2rem;
				line-height: 3.2rem;
				text-align: center;
				transition: color 0.3s ease;

				&:hover {
					color: $color-green-2;
				}
			}
		}
	}

	&__gdpr {
		background-color: $color-black-1;
		padding: 2rem 0;
	}

	&__gdpr-content {
		p {
			color: $color-gray-1;
			font-size: 1.6rem;
			font-weight: 700;

			@include breakpoint(1079 down) {
				text-align: center;
			}

			a {
				color: $color-orange-3;
				text-decoration: none;
			}
		}
	}
}
