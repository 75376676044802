.photo-content-d2 {
	$this: &;

	&.content-left {
		#{$this}__row {
			flex-flow: row-reverse wrap;
		}

		#{$this}__content {
			@include breakpoint(1079px down) {
				padding-left: 0;
				padding-right: 6rem;
			}

			@include breakpoint(809px down) {
				padding-right: 0;
			}
		}
	}

	&__row {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
	}

	&__cell {
		max-width: 95rem;
	}

	&__img {
		flex: 0 1 auto;
		text-align: center;
		width: 42%;

		@include breakpoint(1079px down) {
			width: 50%;
		}

		@include breakpoint(809px down) {
			width: 100%;
		}
	}

	&__img-box {
		@extend %background-image;
		display: flex;
		flex-flow: row wrap;
		align-items: flex-end;
		min-height: 55rem;
		position: relative;
		width: 100%;

		@include breakpoint(1079px down) {
			min-height: 45rem;
		}

		@include breakpoint(809px down) {
			min-height: 35rem;
			margin-bottom: 2rem;
		}
	}

	&__content {
		flex: 0 1 auto;
		padding-left: 6rem;
		padding-right: 6rem;
		width: 58%;

		@include breakpoint(1079px down) {
			padding-left: 6rem;
			padding-right: 0;
			width: 50%;
		}

		@include breakpoint(809px down) {
			padding-left: 0;
			width: 100%;
		}

		.button-box {
			margin-top: 2.5rem;
		}
	}

	&__title {
		@extend %o-title-medium-36;
		color: $color-green-5;
		line-height: 1.2;
		margin-bottom: 3rem;
		margin-top: 0;
		padding-bottom: 3rem;
		position: relative;

		&.max-width-350 {
			max-width: 35rem;
		}

		&:before {
			background-color: $color-yellow-1;
			bottom: 0;
			content: "";
			height: 0.3rem;
			left: 0;
			position: absolute;
			width: 10.5rem;
		}
	}

	&__text {
		margin-bottom: 3rem;

		li,
		p {
			color: $color-gray-10;
		}
	}

	&__lc {
		margin-bottom: 3rem;
		margin-left: 1.5rem;
	}

	&__lc-item {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		line-height: 1.25;
		margin-bottom: 3rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__lc-icon {
		flex: 0 1 auto;
		width: 3rem;
	}

	&__lc-text {
		flex: 0 1 auto;
		padding-left: 1.7rem;
		width: calc(100% - 3rem);

		p {
			line-height: 1.25;
		}
	}
}
