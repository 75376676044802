.elem-intro-d {
	$this: &;

	&__title {
		h2,
		h3 {
			@extend %o-title-bold-28;
			margin-bottom: 0;
		}
	}

	&__text {
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 2rem;

		&:last-child {
			margin-bottom: 0;
		}

		&.max-width-680 {
			max-width: 68rem;
		}
	}

	&__divider {
		background-color: $color-pumpkin-3;
		max-width: 9rem;
		height: 0.2rem;
		margin: 2rem auto;
	}
}
