.intro-content-d {
	$this: &;

	&__grid {
		> * {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__title {
		@extend %o-title-medium-36;
		color: $color-green-5;
		line-height: 1.2;
		margin-top: 0;
		margin-bottom: 2.5rem;
		text-align: center;
	}
}
