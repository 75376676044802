.o-cc-ca-c3 {
	padding: 2.5rem;

	@include breakpoint(767px down) {
		@include shrink();
		min-width: 21rem !important;
	}

	&__top {
		margin-bottom: 2rem;
	}

	&__icon {
		padding-right: 2.5rem;
	}

	&__title {
		@extend %o-title-semibold-36;
		color: $color-onyx;
		line-height: 1;
		margin-bottom: 0;
	}

	&__text {
		&.font-weight-semibold {
			p {
				font-weight: 600;
			}
		}

		&.font-size-20 {
			p {
				font-size: 2rem;
			}
		}
	}
}
