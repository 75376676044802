.column-content-s {
	$this: &;

	&.filter-active {
		#{$this}__sidebar {
			display: none;
		}

		#{$this}__content {
			width: 100%;
		}

		#{$this}__col {
			width: 25%;

			@include breakpoint(1080px down) {
				margin-bottom: 2.4rem;
				width: 50%;
			}

			@include breakpoint(809px down) {
				padding: 0;
				width: 100%;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		#{$this}__col-img {
			@extend %background-image;
			height: 19rem;
			width: 100%;

			@include breakpoint(1080px down) {
				height: 28rem;
			}

			@include breakpoint(1079px down) {
				height: 23.5rem;
			}
		}

		#{$this}__col-content {
			height: calc(100% - 19rem);
			padding: 3.5rem 2rem;

			@include breakpoint(1080px down) {
				height: calc(100% - 28rem);
			}

			@include breakpoint(1079px down) {
				height: calc(100% - 23.5rem);
			}
		}
	}

	&__row {
		display: flex;
		flex-flow: row wrap;
		margin-left: -1.25rem;
		width: calc(100% + 2.5rem);

		@include breakpoint(1079px down) {
			margin-left: 0;
			width: 100%;
		}
	}

	&__sidebar {
		flex: 0 1 auto;
		box-sizing: border-box;
		overflow: hidden;
		padding: 0 1.25rem;
		width: 25%;

		@include breakpoint(1079px down) {
			margin-bottom: 2.4rem;
			padding: 0;
			width: 100%;
		}
	}

	&__sidebar-box {
		@extend %background-image;
		display: flex;
		flex-flow: row wrap;
		align-items: flex-start;
		justify-content: center;
		border-radius: 1rem;
		height: 100%;
		overflow: hidden;
		padding: 4.5rem 2rem;
		position: relative;

		&:before {
			background-color: rgba(21, 67, 68, 0.75);
			content: "";
			left: 0;
			height: 100%;
			position: absolute;
			top: 0;
			width: 100%;
		}

		.btn {
			flex: 0 1 auto;
			align-self: flex-end;
			position: relative;
		}
	}

	&__sidebar-img {
		bottom: 0;
		left: 0;
		position: absolute;

		img {
			display: block;

			@include breakpoint(1079px down) {
				display: none;
			}
		}
	}

	&__sidebar-content {
		position: relative;

		@include breakpoint(1079px down) {
			margin-bottom: 3rem;
			width: 100%;
		}
	}

	&__sidebar-cat {
		@extend %o-text-regular-16;
		color: $color-white-1;
		line-height: 1.2;
		margin-bottom: 1rem;
	}

	&__sidebar-title {
		@extend %o-title-bold-24;
		color: $color-white-1;
		line-height: 1.2;
		margin: 0;
		margin-bottom: 2.5rem;
	}

	&__content {
		flex: 0 1 auto;
		width: 75%;

		@include breakpoint(1079px down) {
			width: 100%;
		}
	}

	&__top {
		flex: 0 1 auto;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		margin: 1rem 0 3rem;
		padding: 0 1.25rem;
		width: 100%;

		@include breakpoint(1079px down) {
			padding: 0;
			margin: 0 0 3rem;
		}
	}

	&__title {
		@extend %o-title-medium-28;
		flex: 1 1 0;
		color: $color-gray-4;
		line-height: 1.2;
		margin-bottom: 0;
		margin-top: 0;
	}

	&__cols {
		display: flex;
		flex-flow: row wrap;

		@include breakpoint(1079px down) {
			margin-left: -1.25rem;
			width: calc(100% + 2.5rem);
		}

		@include breakpoint(809px down) {
			margin-left: 0;
			width: 100%;
		}
	}

	&__col {
		flex: 0 1 auto;
		display: flex;
		flex-flow: row wrap;
		padding: 0 1.25rem;
		width: 33.33%;

		&.d-block {
			display: block;
		}

		&.full {
			width: 100% !important;
		}

		@include breakpoint(1079px down) {
			margin-bottom: 2.4rem;
			width: 50%;
		}

		@include breakpoint(809px down) {
			padding: 0;
			width: 100%;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__col-box {
		border-radius: 0.8rem;
		box-shadow: 0.2rem 0.2rem 1.5rem #e4e8ef;
		overflow: hidden;
		position: relative;
		width: 100%;
	}

	&__col-img {
		@extend %background-image;
		height: 19.5rem;
		width: 100%;
	}

	&__col-content {
		background-color: $color-white-1;
		height: calc(100% - 19.5rem);
		padding: 3.5rem 2rem 5rem;

		@include breakpoint(1080px down) {
			padding: 3.5rem 2rem;
		}
	}

	&__col-cat {
		@extend %o-text-regular-16;
		color: $color-gray-5;
		line-height: 1.2;
		margin-bottom: 1rem;
	}

	&__col-title {
		@extend %o-title-bold-18;
		color: $color-gray-5;
		line-height: 1.2;
		margin-bottom: 0;
		margin-top: 0;
	}

	&__loader {
		display: none;
		text-align: center;
	}

	&__col-message {
		@extend %o-title-bold-18;
		color: $color-gray-5;
		line-height: 1.2;
		margin: 0;
		text-align: center;
	}
}
