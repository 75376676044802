.hero-d {
	$this: &;

	padding: 9.5rem 0;

	@include breakpoint(809px down) {
		padding: 5rem 0;
	}

	&.white {
		#{$this}__title {
			color: $color-green-1;

			&:before {
				background-color: $color-green-1;
				width: 23rem;
			}
		}

		#{$this}__content {
			padding-right: 2.5rem;

			@include breakpoint(1079px down) {
				padding-right: 10rem;
			}

			@include breakpoint(809px down) {
				flex: 0 1 auto;
				padding-right: 0;
				text-align: center;
			}
		}

		#{$this}__text {
			p {
				color: $color-gray-8;
			}
		}
	}

	&__row {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		margin: 0 auto;
		max-width: 90rem;
	}

	&__content {
		flex: 1 1 0;
		padding-right: 10rem;

		@include breakpoint(809px down) {
			flex: 0 1 auto;
			padding-right: 0;
			text-align: center;
		}
	}

	&__title {
		@extend %o-title-bold-36;
		color: $color-white-1;
		line-height: 1.2;
		margin-bottom: 2.5rem;
		margin-top: 0;
		padding-bottom: 2.5rem;
		position: relative;

		&:before {
			background-color: $color-white-1;
			content: "";
			bottom: 0;
			height: 2px;
			left: 0;
			position: absolute;
			width: 88px;

			@include breakpoint(809px down) {
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}

	&__text {
		p {
			color: $color-white-1;
		}
	}

	&__link {
		flex: 0 0 auto;
		position: relative;

		@include breakpoint(809px down) {
			margin: 5rem auto 0;
		}

		a {
			bottom: 0;
			left: 50%;
			min-width: 15rem;
			position: absolute;
			text-align: center;
			width: 100%;
			transform: translateX(-50%);
		}
	}
}
