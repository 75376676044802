%o-text-semibold-18 {
	font-family: $font-neutraface;
	font-size: 18px;
	font-weight: 600;
}

%o-text-bold-48 {
	font-family: $font-neutraface;
	font-size: 48px;
	font-weight: 700;
}

%o-text-bold-33 {
	font-family: $font-neutraface;
	font-size: 33px;
	font-weight: 700;
}

%o-text-bold-24 {
	font-family: $font-neutraface;
	font-size: 24px;
	font-weight: 700;
}

%o-text-bold-18 {
	font-family: $font-neutraface;
	font-size: 18px;
	font-weight: 700;
}

%o-text-bold-16 {
	font-family: $font-neutraface;
	font-size: 16px;
	font-weight: 700;
}

%o-text-regular-24 {
	font-family: $font-neutraface;
	font-weight: 400;

	@include breakpoint(767 down) {
		font-size: 15px;
	}

	@include breakpoint(767) {
		font-size: 20px;
	}

	@include breakpoint(1081) {
		font-size: 24px;
	}
}

%o-text-regular-18 {
	font-family: $font-neutraface;
	font-size: 18px;
	font-weight: 400;
}

%o-text-regular-16 {
	font-family: $font-neutraface;
	font-size: 16px;
	font-weight: 400;
}

%o-text-regular-14 {
	font-family: $font-neutraface;
	font-size: 14px;
	font-weight: 400;
}

%o-text-regular-12 {
	font-family: $font-neutraface;
	font-size: 12px;
	font-weight: 400;
}

%o-text-light-18 {
	font-family: $font-neutraface;
	font-size: 18px;
	font-weight: 300;
}

%o-text-light-16 {
	font-family: $font-neutraface;
	font-size: 16px;
	font-weight: 300;
}

%o-text-opensans-regular-16 {
	font-family: $font-neutraface;
	font-size: 16px;
	font-weight: 400;
}
