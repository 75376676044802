.hero-u {
	$this: &;
	overflow: hidden;
	padding: 8rem 0;
	position: relative;

	@include breakpoint(809px down) {
		background-color: $color-green-1;
	}

	&__bg {
		@extend %background-image;
		content: "";
		height: 100%;
		position: absolute;
		top: 0;
		width: 50%;

		@include breakpoint(809px down) {
			display: none;
		}

		&.full {
			left: 0;
			width: 100%;
		}

		&.left {
			left: 0;
		}

		&.right {
			right: 0;
		}

		&:before {
			background-color: rgba(112, 66, 20, 0.25);
			content: "";
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}
	}

	&__grid {
		position: relative;
		z-index: 2;
	}

	&__content {
		&.max-width-400 {
			margin: 0 auto;
			max-width: 40rem;
		}

		&.max-width-450 {
			margin: 0 auto;
			max-width: 45rem;
		}

		&.max-width-485 {
			margin: 0 auto;
			max-width: 48.5rem;
		}
	}

	&__cat {
		color: $color-white-1;
		font-family: $font-neutraface;
		font-size: 1.6rem;
		font-weight: 300;
		letter-spacing: 0.24rem;
		line-height: 1.2;
		margin-bottom: 2rem;
		text-transform: uppercase;
	}

	&__text {
		margin-bottom: 2rem;

		&.font-size-30 {
			p {
				font-size: 3rem;
			}
		}

		p {
			color: $color-white-1;
			font-size: 2.6rem;
			font-weight: 400;
			line-height: 1.3;

			strong {
				font-weight: 700;
			}
		}
	}

	&__name {
		color: $color-white-1;
		font-family: $font-neutraface;
		font-size: 2rem;
		font-weight: 600;
		line-height: 1.2;
		margin-bottom: 0.5rem;
	}

	&__position {
		color: $color-white-1;
		font-family: $font-neutraface;
		font-size: 1.8rem;
		font-weight: 400;
		line-height: 1.2;
	}

	&__cta {
		margin-top: 2rem;

		.btn-link {
			border-bottom: 0;
			color: $color-orange-3;
		}
	}

	&__pattern {
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);

		@include breakpoint(809px down) {
			display: none;
		}
	}
}
