%header-me-svg-arrow {
	margin-top: 0.1rem;
	margin-left: 1rem;
	position: absolute;
	top: 50%;
	right: -2rem;
	transform: translateY(-50%);
	transition: right 0.3s ease;
}

%header-me-svg-arrow-container {
	color: inherit;
	font: inherit;
	position: relative;
}

%header-me-svg-arrow-hover {
	position: relative;

	&:hover {
		> svg {
			right: -3rem;
		}
	}
}

.btn {
	$trans: 0.3s ease 0s;

	background-color: $color-teal-3;
	border: 2px solid $color-teal-1;
	border-radius: 10rem;
	box-sizing: border-box;
	color: $color-white-1;
	display: inline-block;
	font-family: $font-neutraface;
	font-size: 1.6rem;
	font-weight: 600;
	line-height: 1.2;
	min-width: 19.5rem;
	padding: 1.8rem 3.5rem;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	transition: background $trans, border-color $trans, color $trans;

	@include breakpoint(767 down) {
		font-size: 1.5rem;
		min-width: 17.5rem;
		padding: 1.8rem 3rem;
	}

	&:hover {
		background-color: $color-teal-1;
		color: $color-white-1;
		text-decoration: none;
	}

	&:focus {
		color: $color-white-1;
	}

	&.layout-careers {
		min-width: 28rem;
	}

	&.color-default-2 {
		background: linear-gradient($color-midnight-green-3, $color-midnight-green-3) padding-box, linear-gradient(to right, #00808c, #f0de30) border-box;
		border: 2px solid transparent;
		color: $color-white-1;
		position: relative;

		&:hover {
			background: linear-gradient($color-teal-1, $color-teal-1) padding-box, linear-gradient(to right, #00808c, #f0de30) border-box;
			border: 2px solid transparent;
			color: $color-white-1;
		}

		&:focus {
			color: $color-white-1;
		}
	}

	&.color-green {
		background-color: $color-green-2;
		border-color: $color-green-2;
		color: $color-white-1;

		&:hover {
			background-color: $color-green-1;
			border-color: $color-green-1;
			color: $color-white-1;
		}

		&:focus {
			color: $color-white-1;
		}
	}

	&.color-white {
		background-color: $color-white-1;
		border-color: $color-white-1;
		color: $color-green-2;

		&:hover {
			background-color: $color-green-2;
			border-color: $color-green-2;
			color: $color-white-1;
		}

		&:focus {
			color: $color-green-1;
		}
	}
}

.btn-gradient {
	$trans: 0.3s ease 0s;

	background-color: $color-black-1;
	border-radius: 10rem;
	box-sizing: border-box;
	color: $color-white-1;
	display: inline-block;
	font-family: $font-neutraface;
	font-size: 1.6rem;
	font-weight: 600;
	line-height: 1.2;
	min-width: 19.5rem;
	padding: 1.6rem 3.6rem;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	transition: background-color $trans, border-color $trans, color $trans;
	position: relative;

	&::after {
		background: linear-gradient(to right, #00808c 0%, #f0de30 100%);
		display: block;
		content: "";
		border-radius: 10rem;
		position: absolute;
		top: -1px;
		bottom: -1px;
		left: -1px;
		right: -1px;
		z-index: -1;
		transition: background-color $trans;
	}

	&:hover {
		background-color: $color-white-1;
		color: $color-black-1;
		text-decoration: none;

		&::after {
			background: linear-gradient(to right, #ffffff 0%, #ffffff 100%);
		}
	}

	&:focus {
		color: $color-black-1;
	}
}

.link-arrow {
	color: $color-night;
	font-family: $font-neutraface;
	font-size: 1.4rem;
	font-weight: 700;
	line-height: 1.2;
	position: relative;
	text-decoration: none;

	&:hover {
		color: $color-night !important;
		text-decoration: none;
	}

	span {
		@extend %header-me-svg-arrow-container;
	}

	svg {
		@extend %header-me-svg-arrow;
	}
}

.hs-button {
	background-color: $color-white-1;
	border: 0.2rem solid $color-white-1;
	border-radius: 10rem;
	box-sizing: border-box;
	color: $color-green-2;
	display: inline-block;
	font-family: $font-neutraface;
	font-size: 1.6rem;
	font-weight: 700;
	line-height: 1.2;
	min-width: 22.4rem;
	padding: 1rem 2rem;
	text-decoration: none;
	transition: all 0.3s;

	&:hover {
		background-color: transparent;
		border-color: $color-white-1;
		color: $color-white-1;
		text-decoration: none;
	}

	&:focus {
		color: $color-green-2;
	}
}

.btn-outline {
	@extend .btn;
	background-color: transparent;
	border-color: $color-white-1;

	&:hover {
		background-color: $color-white-1;
		color: $color-gray-5;
	}

	&:focus {
		color: $color-white-1;
	}
}

.btn-link {
	color: $color-green-2;
	font-family: $font-neutraface;
	font-size: 1.6rem;
	font-weight: 700;
	line-height: 1.2;
	opacity: 1;
	text-decoration: none;
	transition: all 0.3s;

	&:hover {
		color: $color-green-1;
		opacity: 0.5;
		text-decoration: none;
	}

	&:focus {
		color: $color-green-1;
	}
}

.btn-arrow {
	color: $color-gray-4;
	display: inline-block;
	font-family: $font-neutraface;
	font-size: 2.4rem;
	font-weight: 400;
	line-height: 1.2;
	opacity: 1;
	padding-right: 3.5rem;
	position: relative;
	text-decoration: none;
	transition: all 0.3s;

	&:before {
		content: url("../images/arrow-right-ee7c32.svg");
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
	}

	&:hover {
		opacity: 0.5;
		text-decoration: none;
	}

	&:focus {
		color: $color-gray-4;
	}
}

.btn-arrow-2 {
	color: $color-green-2;
	display: inline-block;
	font-family: $font-neutraface;
	font-size: 10.2rem;
	font-weight: 700;
	line-height: 1.2;
	opacity: 1;
	padding-right: 2rem;
	position: relative;
	text-decoration: none;
	transition: all 0.3s;

	&:before {
		content: url("../images/arrow-right-ee7c32-2.svg");
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
	}

	&:hover {
		color: $color-green-1;
		opacity: 0.5;
		text-decoration: none;
	}
}

.btn__container {
	&--outline {
		background: $color-teal-1;
		background: linear-gradient(270deg, $color-teal-1 0%, $color-yellow-1 100%);
		border-radius: 100rem;
		cursor: pointer;
		display: inline-block;
		position: relative;

		&:before {
			background: $color-teal-1;
			background: linear-gradient(
				90deg,
				$color-teal-1 0%,
				$color-yellow-1 100%
			);
			border-radius: 100rem;
			content: "";
			cursor: pointer;
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			transition: opacity 0.6s ease;
			width: 100%;
		}

		&:hover {
			&::before {
				opacity: 0;
			}
		}
	}
}

.btn--outline {
	border-radius: 100rem;
	background-color: $color-black-1;
	border: none;
	margin: 2px;
	position: relative;
	transition: color 0.6s ease;
	z-index: 1;

	&:hover {
		background-color: $color-black-1;
		color: $color-teal-3;
	}
}
