.column-content-cc {
	$this: &;

	&.col-gap-50 {
		$gap: 5rem;

		&.layout-unboxed {
			#{$this}__col {
				@include breakpoint(1080px up) {
					padding: 0;
				}
			}
		}

		#{$this}__row {
			@include breakpoint(1080px up) {
				margin-left: -$gap;
				margin-right: -$gap;
			}
		}

		#{$this}__col {
			margin-bottom: 3.5rem;

			@include breakpoint(1080px up) {
				padding-left: $gap;
				padding-right: $gap;
			}

			@include breakpoint(1079px down) {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	&__col {
		> * {
			margin-top: 4.5rem;

			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		&.col-25p {
			@include breakpoint(1080px up) {
				width: 25%;
			}
		}

		&.col-30p {
			@include breakpoint(1080px up) {
				width: 30%;
			}
		}

		&.col-45p {
			@include breakpoint(1080px up) {
				width: 45%;
			}
		}

		&.col-40p {
			@include breakpoint(1080px up) {
				width: 40%;
			}
		}

		&.col-50p {
			@include breakpoint(1080px up) {
				width: 50%;
			}
		}

		&.col-55p {
			@include breakpoint(1080px up) {
				width: 55%;
			}
		}

		&.col-60p {
			@include breakpoint(1080px up) {
				width: 60%;
			}
		}

		&.col-70p {
			@include breakpoint(1080px up) {
				width: 70%;
			}
		}
	}

	&__text {
		&.color-text-white {
			h1,
			h2,
			h3,
			h4,
			h5,
			h6,
			li,
			p {
				color: $color-white-1;
			}
		}

		h2,
		h3 {
			@extend %o-title-medium-24;
			margin-bottom: 2rem;
			color: $color-black-1;
		}

		p {
			color: $color-black-1;
		}

		li {
			color: $color-black-1;
			line-height: 1.2;
		}

		.button-box {
			margin-top: 3rem;

			ul {
				margin-left: 0;

				li {
					list-style-type: none;
					margin-bottom: 2rem;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}

	&__te {
		border-radius: 1.5rem;

		li,
		p {
			color: $color-black-1;
			line-height: 1.5;
		}

		img {
			margin-bottom: 1.5rem;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__te-wrapper {
		padding: 3rem;
	}
}
